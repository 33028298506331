(function (w, d) {

  let homeAnimations = {

    init: function () {

      const homeBody = d.getElementById('homeBody'),
        waveTimeline = gsap.timeline(),
        heroHeight = d.querySelector('.hero').clientHeight,
        rheemParallax = gsap.fromTo(
          '.rheemCompressor',
          {
            y: 100
          },
          {
            y: 0,
            ease: 'linear'
          }
        ),
        firstParallaxAnimation = gsap.to(
          '#firstParallaxImg',
          {
            yPercent: -20,
            ease: 'linear'
          }
        ),
        waveScrollTrigger = ScrollTrigger.create({
          animation: waveTimeline,
          trigger: '#difference',
          start: function (self) {
            return self.trigger.offsetTop + self.trigger.offsetHeight / 2;
          },
          end: '+=50%',
          scrub: .7
        }),
        RheemUnitsTrigger = ScrollTrigger.create({
          animation: rheemParallax,
          trigger: '#rheem',
          start: function (self) {
            return self.trigger.offsetTop;
          },
          end: function (self) {
            const distanceTillPast = self.trigger.offsetHeight + self.trigger.offsetTop + w.innerHeight;
            if (distanceTillPast > d.body.clientHeight) {
              return d.body.clientHeight;
            } else {
              return self.trigger.offsetHeight + self.trigger.offsetTop + w.innerHeight;
            }
          },
          scrub: .7
        }),
        heroScrollTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: '#pageWrapper',
            start: 'top top',
            endTrigger: '.scrollSection',
            pin: true,
            scrub: true
          }
        }),
        firstParallax = ScrollTrigger.create({
          animation: firstParallaxAnimation,
          trigger: '#firstParallax',
          scrub: .5,
          start: function (self) {
            return heroHeight + self.trigger.getBoundingClientRect().y;
          },
          end: function (self) {
            return heroHeight + self.trigger.getBoundingClientRect().y + self.trigger.clientHeight;
          }
        })
      ;

      heroScrollTimeline.set(homeBody, {z: -177, y: 200})
        .to('.scrollSection', 1, {yPercent: -100, ease: 'none'})
        .to(homeBody, 1, {z: 0, y: 0}, "-=1")
      ;

      waveTimeline.set('.waveCurve', {drawSVG: "0%", strokeWidth: "1px"})
        .to('.waveCurve', 1, {drawSVG: "100%", ease: "linear"})
        .to('.waveCurve', .1, {fill: "#fff"})
      ;

    }

  }

  w.addEventListener('load', loadEvents);

  function loadEvents() {
    if (Modernizr.es6object) {
      homeAnimations.init();
    }

    const video = document.getElementById('heroVid');
    if(Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource('https://player.vimeo.com/external/878003934.m3u8?s=5682d9a1be8b96166900a47eabb6f9e549ca6fa6&logging=false');
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED,function() {
        video.play();
      });
    }
      // hls.js is not supported on platforms that do not have Media Source Extensions (MSE) enabled.
      // When the browser has built-in HLS support (check using `canPlayType`), we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video element throught the `src` property.
    // This is using the built-in support of the plain video element, without using hls.js.
    else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = 'https://player.vimeo.com/external/878003934.m3u8?s=5682d9a1be8b96166900a47eabb6f9e549ca6fa6&logging=false';
      video.addEventListener('canplay',function() {
        video.play();
      });
    }
  }

})(window, document);
